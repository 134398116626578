<script>
import { ref } from "vue";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import addButton from "../../../components/widgets/addButton.vue";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import Swal from "sweetalert2";
import vueDropzone from "dropzone-vue3";
import sectionImageCard from "./component/sectionImageCard.vue";
import loaderProgress from "../../../components/widgets/loaderProcess.vue";

/**
 * Projects-create component
 */
export default {
  page: {
    title: "Video list",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    addButton,
    vueDropzone,
    sectionImageCard,
    loaderProgress,
  },
  setup() {
    const dropzoneOptions = ref({
      url: "https://httpbin.org/post",
      thumbnailWidth: 150,
      maxFilesize: 5,
      headers: { "My-Awesome-Header": "header value" },
      acceptedFiles: "image/*",
    });

    const acceptedFiles = ref([]);
    const myVueDropzone = ref(null);

    const generateNewFileName = (file) => {
      const timestamp = new Date().getTime();
      const newName = `${file.name}_${timestamp}`;
      return newName;
    };

    const onUploadSuccess = (file, response) => {
      console.log("Upload success:", response);
      getAllFiles();
    };

    const onFileAdded = (file) => {
      const newName = generateNewFileName(file);
      const renamedFile = new File([file], newName, { type: file.type });
      acceptedFiles.value.push({
        originalFile: file,
        newName: newName,
        renamedFile: renamedFile,
      });
      console.log("File added:", renamedFile);
    };

    const getAllFiles = () => {
      const imageList = [];

      if (myVueDropzone.value) {
        const dropzoneComponent = myVueDropzone.value.dropzone;
        const files = dropzoneComponent.getAcceptedFiles();
        files.forEach((originalFile, index) => {
          // Générer un nouveau nom unique pour chaque fichier
          const newName = `${index}_${
            originalFile.name
          }_${new Date().getTime()}`;

          // Créer un nouveau fichier avec le nouveau nom
          const renamedFile = new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
          });

          // Ajouter le fichier renommé au tableau `imageList`
          imageList.push(renamedFile);
        });

        console.log("Liste des fichiers renommés :", imageList);
      }

      return imageList;
    };

    return {
      dropzoneOptions,
      onUploadSuccess,
      onFileAdded,
      getAllFiles,
      acceptedFiles,
      myVueDropzone,
    };
  },

  data() {
    return {
      process: false,
      spinner: false,
      progress: 0,
      titre: "",
      searchQuery: "",
      searchProgram: "",
      addMovieModal: false,
      choiceProgramModal: false,
      addSectionMovieModal: false,
      addSectionProgramModal: false,
      addSectionImageModal: false,
      SectionMovieModalSelectList: false,
      SectionProgramModalSelectList: false,
      title: "Sections",
      items: [
        {
          text: "Sections",
          active: true,
        },
      ],
      daterange: ref(new Date()),
      videos: [],
      programs: [],
      tags: [],
      programmes: [],
      categorieList: [],
      idPrograms: [],
      idVideos: [],
      sectionPrograms: {
        title: "",
        description: "",
      },
      sectionVideos: {
        title: "",
        description: "",
      },
      sectionImage: {
        title: "",
        description: "",
      },
      sectionsVideoList: [],
      sectionsImageList: [],
      sectionsProgramsList: [],
      filesImages: [],
      listeDesSectionDesVideos: [],
      imageList: [],
    };
  },
  methods: {
    showAddSectionImageModal() {
      this.addMovieModal = false;
      this.addSectionImageModal = !this.addSectionImageModal;
    },
    getSectionImage() {
      Api.get("/streamvodv2/api/v2/image-section/all")
        .then((response) => {
          this.spinner = false;
          this.sectionsImageList = response.data.content;
          console.log(this.sectionsImageList);
        })
        .catch((error) => {
          this.spinner = false;
          Erreur.gestionErreur(error.message);
        });
    },

    createSectionImage() {
      this.process = true;
      this.addSectionImageModal = false;
      this.addSectionImageModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Ajout d'une section d'image";
      console.log(this.getAllFiles());
      console.log(this.imageList);
      Api.postFormData(
        `/streamvodv2/rest/v2/image-section/create?description=${this.sectionImage.description}&title=${this.sectionImage.title}`,
        {
          files: this.getAllFiles(),
        },
        { onUploadProgress }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Section Créée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
  },

  mounted() {
    this.spinner = true;
    this.getSectionImage();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loaderProgress :visible="process" :progress="progress" :title="titre" />

    <div
      style="display: flex; justify-content: space-between; margin-bottom: 2rem"
    >
      <div></div>
      <addButton
        @click="showAddSectionImageModal()"
        :text="'Créer une section'"
      ></addButton>
    </div>

    <!-- Liste des sections -->

    <div style="text-align: center; font-size: 3em" v-if="spinner == true">
      <b-spinner variant="success" role="status"></b-spinner>
    </div>
    <div
      style="text-align: center; font-size: 3em"
      v-if="spinner == false && sectionsImageList.length == 0"
    >
      <span style="font-size: 1.5rem">Aucune section trouvée !</span>
    </div>

    <div class="row">
      <sectionImageCard
        v-for="section in sectionsImageList"
        :key="section.id"
        :section="section"
      ></sectionImageCard>
    </div>

    <!-- Modal pour la création d'une section d'image -->
    <!-- ========================================================================================= -->
    <b-modal
      v-model="addSectionImageModal"
      id="modal-xl"
      size="xl"
      title="Créer un section d'images"
      title-class="font-18"
      hide-footer
    >
      <div>
        <form>
          <div class="row">
            <div class="col-lg-12">
              <label class="col-form-label">Titre</label>
              <div class="col-md-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="sectionImage.title"
                  placeholder="Entrez le titre de la section"
                />
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="form-group col-lg-12">
              <label for="projectbudget" class="col-form-label">Images</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  @vdropzone-success="onUploadSuccess"
                  @vdropzone-added-file="onFileAdded"
                />
              </div>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="sectionImage.description"
                placeholder="Entrez la description"
              ></textarea>
            </div>
          </div>
        </form>
        <div style="text-align: center">
          <button @click="createSectionImage()" class="btn c2play-primary">
            Ajouter
          </button>
        </div>
      </div>
    </b-modal>
    <!-- end row -->
  </Layout>
</template>
